import { useQuery } from '@tanstack/react-query';

import reportService, { ReportsUrls } from 'api/services/report';
import { OpenBatchSummary } from 'types/reports';

const useOpenBatchAmountData = (enabled: boolean) => {
  const { data, isFetching, isError, isLoading, isSuccess, refetch, dataUpdatedAt } = useQuery<OpenBatchSummary>({
    queryKey: [ReportsUrls.getOpenBatchSummary],
    queryFn: async () => {
      return await reportService.GetOpenBatchSummary();
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: enabled,
    refetchInterval: 300000,
  });

  return { data, isFetching, isError, isSuccess, isLoading, refetch, dataUpdatedAt };
};

export default useOpenBatchAmountData;
