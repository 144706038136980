import { useMemo, useState } from 'react';

import { VegaBox, VegaCard, VegaFlex, VegaFont, VegaInputSelect, VegaSkeleton } from '@heartlandone/vega-react';
import { useTranslation } from 'next-i18next';

import ProgressBanner from 'components/dashboard/applicationTracker/progressBanner';
import ProgressTrackerBar, { ProgressLevelDisplay } from 'components/progressTrackerBar';
import useApplicationStatus from 'hooks/applicationTracker/useApplicationStatus';

import applicationTrackerConstants from './applicationTracker.constants';
import { ApplicationState, ApplicationStateResult } from './applicationTracker.types';

const {
  currentFullApplicationState,
  fullStepCount,
  testApiStateLabel,
  testStateEnvironments,
  testStateDropdownId,
  testStepCounterId,
} = applicationTrackerConstants;

const trackerDropdownSource = [
  ...Object.keys(currentFullApplicationState).map((appState) => ({
    id: appState,
    displayName: appState,
  })),
  { id: testApiStateLabel, displayName: testApiStateLabel },
];

function ApplicationTracker() {
  const { t } = useTranslation('application-tracker');
  const { data, isError, isLoading } = useApplicationStatus();

  const [testState, setTestState] = useState<ApplicationStateResult | null>(null);

  const currentState = useMemo(() => {
    return testState ?? data ?? { state: ApplicationState.ApplicationReceived, statusDate: '' };
  }, [data, testState]);

  const currentProgress = useMemo<ProgressLevelDisplay[]>(
    () =>
      currentFullApplicationState[currentState.state]?.segments.map((stateProgressMap) => ({
        displayName: t(`${stateProgressMap.state}.tooltip`),
        progressLevel: stateProgressMap.progress,
        isEmphasized: stateProgressMap.isEmphasized,
      })) || [],
    [currentState, t],
  );

  const showProgress = !isLoading && !isError;

  const handleTestStateChange = (evt: CustomEvent) => {
    const selectedState = evt.detail;
    setTestState(
      selectedState === testApiStateLabel ? null : ({ state: selectedState, statusDate: '' } as ApplicationStateResult),
    );
  };

  return (
    <VegaCard padding={'size-24'} style={{ gridArea: 'span 1 / span 4' }}>
      <VegaFlex direction="col" gap="size-20">
        <VegaFlex direction="row" justifyContent="space-between">
          <VegaFont variant="font-h4">{t('title')}</VegaFont>
          {showProgress && currentFullApplicationState[currentState.state] && (
            <VegaFont
              variant="font-p1-short"
              color="text-secondary"
              data-testid={testStepCounterId + currentFullApplicationState[currentState.state].step}
            >
              {`${t('Step')} ${currentFullApplicationState[currentState.state].step} ${t('of')} ${fullStepCount}`}
            </VegaFont>
          )}
        </VegaFlex>
        {testStateEnvironments.includes(process.env.NEXT_PUBLIC_ENV!) && (
          <VegaInputSelect
            style={{ width: '35%' }}
            data-testid={testStateDropdownId}
            source={trackerDropdownSource}
            onVegaChange={handleTestStateChange}
          />
        )}
      </VegaFlex>
      <VegaBox width="100%" margin={{ top: 'size-16' }}>
        {isLoading && (
          <VegaFlex justifyContent={'center'} alignItems={'center'} style={{ height: '26px' }}>
            <VegaSkeleton width={'100%'} type="ellipse" height={18} />
          </VegaFlex>
        )}
        {isError && (
          <VegaFont color="text-error" variant="font-btn-label-lg">
            {t('Error')}
          </VegaFont>
        )}
        {showProgress && (
          <>
            <ProgressTrackerBar states={currentProgress} />
            <ProgressBanner state={currentState.state} date={currentState.statusDate} />
          </>
        )}
      </VegaBox>
    </VegaCard>
  );
}

export default ApplicationTracker;
