import { useQuery } from '@tanstack/react-query';

import reportService, { ReportsUrls } from 'api/services/report';
import { BatchRange, GrossSalesResult } from 'types/reports';

const useGrossSalesData = (range: BatchRange, minDate?: string, maxDate?: string) => {
  const { data, isFetching, isError, isLoading, isSuccess } = useQuery<GrossSalesResult>({
    queryKey: [ReportsUrls.getGrossSales, range, minDate, maxDate],
    queryFn: async () => {
      return await reportService.GetGrossSales(range, minDate, maxDate);
    },
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
    staleTime: 300000,
  });

  return { data, isFetching, isError, isSuccess, isLoading };
};

export default useGrossSalesData;
