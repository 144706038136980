import React from 'react';

import { VegaCard, VegaFlex, VegaFont, VegaGrid } from '@heartlandone/vega-react';
import { format } from 'date-fns';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import applicationTrackerConstants from 'components/dashboard/applicationTracker/applicationTracker.constants';
import { ApplicationState } from 'components/dashboard/applicationTracker/applicationTracker.types';

export type ProgressStatusProps = {
  state: ApplicationState;
  date: string;
};

function ProgressBanner({ state, date }: ProgressStatusProps) {
  const { t } = useTranslation('application-tracker');
  const { imagePaths, progressBannerId } = applicationTrackerConstants;
  const formattedDate = date.length > 0 ? format(new Date(date), 'Pp') : '';
  const bannerText = t(`${state}.bannerText`); // doing this because can't seem to concatenate translated, interpolated string to other interpolated string

  return (
    <>
      <VegaCard backgroundColor={'bg-quaternary'} padding={'size-16'} style={{ margin: '24px 0' }} variant={'border'}>
        <VegaFlex style={{ width: '100%' }}>
          <Image
            src={imagePaths[state]}
            alt={'placeholder-image'}
            width={50}
            height={50}
            style={{ margin: '0 12px 0 0' }}
          />
          <VegaGrid column={'1-auto'} row={'2-auto'}>
            <VegaFont variant={'font-h6'} data-testid={`${progressBannerId}-title`}>
              {t(`${state}.tooltip`)}
            </VegaFont>
            <VegaFont variant={'font-field-label'} color={'text-secondary'} data-testid={`${progressBannerId}-status`}>
              {`${bannerText} ${formattedDate}`}
            </VegaFont>
          </VegaGrid>
        </VegaFlex>
      </VegaCard>
      <VegaFlex>
        <VegaFont data-testid={`${progressBannerId}-description`}>{t(`${state}.description`)}</VegaFont>
      </VegaFlex>
      {state === ApplicationState.ApplicationPending && (
        <VegaFlex>
          <VegaFont variant={'font-field-label-em'} data-testid={`${progressBannerId}-additional-info`}>
            {t(`additionalInfo`)}
          </VegaFont>
        </VegaFlex>
      )}
    </>
  );
}

export default ProgressBanner;
