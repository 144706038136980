import { ProgressLevel } from 'components/progressTrackerBar';

import { ApplicationState, ApplicationTrackerSegmentState } from './applicationTracker.types';

const currentFullApplicationState: Record<ApplicationState, ApplicationTrackerSegmentState> = {
  [ApplicationState.ApplicationReceived]: {
    segments: [
      {
        state: ApplicationState.ApplicationReceived,
        progress: ProgressLevel.Completed,
        isEmphasized: true,
      },
      {
        state: ApplicationState.ApplicationUnderReview,
        progress: ProgressLevel.NoProgress,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationPending,
        progress: ProgressLevel.NoProgress,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationProcessComplete,
        progress: ProgressLevel.NoProgress,
        isEmphasized: false,
      },
    ],
    step: 1,
  },
  [ApplicationState.ApplicationUnderReview]: {
    segments: [
      {
        state: ApplicationState.ApplicationReceived,
        progress: ProgressLevel.Completed,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationUnderReview,
        progress: ProgressLevel.Completed,
        isEmphasized: true,
      },
      {
        state: ApplicationState.ApplicationPending,
        progress: ProgressLevel.NoProgress,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationProcessComplete,
        progress: ProgressLevel.NoProgress,
        isEmphasized: false,
      },
    ],
    step: 2,
  },
  [ApplicationState.ApprovedByUnderwriting]: {
    segments: [
      {
        state: ApplicationState.ApplicationReceived,
        progress: ProgressLevel.Completed,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationUnderReview,
        progress: ProgressLevel.Completed,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApprovedByUnderwriting,
        progress: ProgressLevel.Completed,
        isEmphasized: true,
      },
      {
        state: ApplicationState.ApplicationProcessComplete,
        progress: ProgressLevel.NoProgress,
        isEmphasized: false,
      },
    ],
    step: 3,
  },
  [ApplicationState.ApplicationPending]: {
    segments: [
      {
        state: ApplicationState.ApplicationReceived,
        progress: ProgressLevel.Completed,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationUnderReview,
        progress: ProgressLevel.Completed,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationPending,
        progress: ProgressLevel.InProgress,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationProcessComplete,
        progress: ProgressLevel.NoProgress,
        isEmphasized: false,
      },
    ],
    step: 3,
  },
  [ApplicationState.ApplicationProcessComplete]: {
    segments: [
      {
        state: ApplicationState.ApplicationReceived,
        progress: ProgressLevel.Completed,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationUnderReview,
        progress: ProgressLevel.Completed,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApprovedByUnderwriting,
        progress: ProgressLevel.Completed,
        isEmphasized: false,
      },
      {
        state: ApplicationState.ApplicationProcessComplete,
        progress: ProgressLevel.Completed,
        isEmphasized: true,
      },
    ],
    step: 4,
  },
};

const baseImageUrl = `${process.env.NEXT_PUBLIC_IMAGE_URL}/myAccount/appTracker`;
const imagePaths: Record<ApplicationState, string> = {
  [ApplicationState.ApplicationReceived]: `${baseImageUrl}/applicationReceived.png`,
  [ApplicationState.ApplicationUnderReview]: `${baseImageUrl}/applicationUnderReview.png`,
  [ApplicationState.ApplicationPending]: `${baseImageUrl}/applicationPending.png`,
  [ApplicationState.ApprovedByUnderwriting]: `${baseImageUrl}/applicationApproved.png`,
  [ApplicationState.ApplicationProcessComplete]: `${baseImageUrl}/applicationComplete.png`,
};

const applicationTrackerConstants = {
  currentFullApplicationState,
  fullStepCount: 4,
  testApiStateLabel: 'Use API State',
  testStateEnvironments: ['TST'],
  testStateDropdownId: 'application-tracker-test-state',
  testStepCounterId: 'application-tracker-step-',
  progressBannerId: 'progress-banner-',
  imagePaths,
};

export default applicationTrackerConstants;
