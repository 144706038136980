import { useQuery } from '@tanstack/react-query';

import userService, { UserUrls } from 'api/services/user';
import { PendingOwner } from 'types/user';

const usePendingOwners = () => {
  const { data, isFetching, isError, isSuccess } = useQuery<PendingOwner[]>({
    queryKey: [UserUrls.GetPendingOwners],
    queryFn: async () => {
      return await userService.GetPendingOwners();
    },
    refetchOnWindowFocus: false,
  });

  return { data, isFetching, isError, isSuccess };
};

export default usePendingOwners;
