import { useQuery } from '@tanstack/react-query';

import reportService, { ReportsUrls } from 'api/services/report';
import { SalesRepresentative } from 'types/reports';

const useSalesRepresentativeData = (isEnabled = true) => {
  const { data, isFetching, isError, isSuccess } = useQuery<SalesRepresentative>({
    queryKey: [ReportsUrls.getSalesRepresentative],
    queryFn: async () => {
      return await reportService.GetSalesRepresentative();
    },
    refetchOnWindowFocus: false,
    enabled: isEnabled,
  });

  return { data, isFetching, isError, isSuccess };
};

export default useSalesRepresentativeData;
