import { useQuery } from '@tanstack/react-query';

import reportService, { ReportsUrls } from 'api/services/report';
import { BatchRange, PaymentTypeDataResult } from 'types/reports';

const usePaymentTypeData = (range: BatchRange, minDate?: string, maxDate?: string) => {
  const { data, isFetching, isError, isLoading, isSuccess } = useQuery<PaymentTypeDataResult[]>({
    queryKey: [ReportsUrls.getPaymentTypeChartData, range, minDate, maxDate],
    queryFn: async () => {
      return await reportService.GetPaymentTypeChartData(range, minDate, maxDate);
    },
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
    staleTime: 300000,
  });

  return { data, isFetching, isError, isSuccess, isLoading };
};

export default usePaymentTypeData;
