import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import dashboardService from 'api/services/dashboard';
import { DashboardControllerUrls } from 'api/services/dashboard/dashboardService';
import { UserContext } from 'components/user/auth/userContext';
import { CustomDashboard } from 'types/dashboard';

const useCustomDashboard = (uniqueKey: string) => {
  const user = useContext(UserContext);
  const { data, isLoading, isError, isSuccess } = useQuery<CustomDashboard>({
    queryKey: [
      DashboardControllerUrls.GetCustomDashboardRoute,
      user.activeCustomer?.idValue,
      user.activeCustomer?.idType,
      user.userEmail,
      uniqueKey,
    ],
    queryFn: () => dashboardService.GetCustomDashboard(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, isError, isSuccess };
};

export default useCustomDashboard;
