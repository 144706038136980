import { ProgressLevel } from 'components/progressTrackerBar';

export enum ApplicationState {
  ApplicationReceived = 'Application Received',
  ApplicationUnderReview = 'Application Under Review',
  ApplicationPending = 'Application Pending',
  ApprovedByUnderwriting = 'Approved By Underwriting',
  ApplicationProcessComplete = 'Application Process Complete',
}

export type ApplicationTrackerSegmentState = {
  segments: Array<{ state: ApplicationState; progress: ProgressLevel; isEmphasized: boolean }>;
  step: number;
};

export type ApplicationStatus = {
  status: string;
  statusDate: string;
};

export type ApplicationStateResult = {
  state: ApplicationState;
  statusDate: string;
};
