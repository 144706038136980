import { useQuery } from '@tanstack/react-query';

import reportService, { ReportsUrls } from 'api/services/report';
import { LastDepositResult } from 'types/reports';

const useLastDepositData = () => {
  const { data, isFetching, isError, isLoading, isSuccess } = useQuery<LastDepositResult>({
    queryKey: [ReportsUrls.getLastDeposit],
    queryFn: async () => {
      return await reportService.GetLastDeposit();
    },
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
    staleTime: 300000,
  });

  return { data, isFetching, isError, isSuccess, isLoading };
};

export default useLastDepositData;
